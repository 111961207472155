import styled from "styled-components";

export const CloseButton = styled.button`
  border: none;
  background-color: #f60;
  align-self: flex-end;
  font-size: 30px;
  opacity: 0.7;

  &:hover{
	opacity: 1;
  }
`;

export const Text = styled.p`
	color: white;
	font-size: 18px;
	font-weight: 700;
	margin: 0;
	padding: 0;
`;

export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
`;
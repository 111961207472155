export const setHash = hash => ({
    type: 'SET_HASH',
    hash
});

export const addFieldColor = (column, row, color, defaultWhenTheSame) => ({
    type: 'ADD_FIELD_COLOR',
    field: {column, row, color},
    defaultWhenTheSame
});

export const loadImage = image => ({
    type: 'LOAD_IMAGE',
    image
});

export const changeBoardSize = (columns, rows) => ({
    type: 'CHANGE_BOARD_SIZE',
    columns,
    rows
});

export const clearBoard = () => ({type: 'CLEAR_BOARD'});

export const changeSelectedPaletteName = name => ({
    type: 'CHANGE_SELECTED_PALETTE_NAME',
    name
});

export const loadBoard = (fields, columns, rows, palette) => ({
    type: 'LOAD_BOARD',
    fields,
    columns,
    rows,
    palette
});


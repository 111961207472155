import styled from "styled-components";

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const Image = styled.div`
    min-width: 75px;
    height: 75px;
    background: #fff;
    box-sizing: border-box;
    
    &.first {
        border-right: 2px solid #00458a;
    }
    
    & a {
        width: 81px;
        padding: 4px;
        text-align: center;
        text-decoration: none;
        background: #fff;
    }
    
    &:hover path, &:hover span {
        color: #16a903;
        fill: #16a903!important;
    }
    
    & a svg {
        padding: 2px;
        width: 38px;
        height: 38px;
        position: relative;
        top: 7px;
        left: 12px;
    }
    
    & a span {
        display: block;
        color: #00458a;
        font-weight: 700;
        white-space: nowrap;
        font-size: 12px;
    }

    @media(max-width: 768px) {
        margin-top: -24px;
    }
`;

export const ButtonText = styled.span`
    @media(max-width: 1150px) {
        display: none;
    }
`;
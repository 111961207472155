import styled from "styled-components";

export const ColorsToolContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ColorsLabel = styled.div`
    background-color: #3ba900;
    font-weight: 700;
    color: #fff;
    font-size: 1rem;
    padding: 0 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    
    & > div > svg {
        color: #16e503;
        margin-left: 5px;

        &:hover {
            color: white;
        }
    }
`;

export const ColorsContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
    background: #004a45;
    
    ${props => props.collapsed && 'height: 0; padding: 0;'}    
`;

const shadow = 'box-shadow: 0 0 5px 2px white;';
export const Color = styled.div`
    width: 50px;
    height: 50px;
    background-color: ${props => 'rgb(' + props.color.r + ',' + props.color.g + ',' + props.color.b + ')'};
    margin: 10px;
    cursor: pointer;
    ${props => props.selected && shadow}
    
    &:hover {
        ${shadow}
    }
`;

export const zoomIn = () => ({
    type: 'ZOOM_IN'
});

export const zoomOut = () => ({
    type: 'ZOOM_OUT'
});

export const zoom100 = () => ({
    type: 'ZOOM_100'
});

const initialState = {
    isVisible: false
};

export const rightMenu = (state = initialState, action) => {
    if (action.type === 'HIDE_RIGHT_MENU') {
        state.isVisible = false;
    }

    if (action.type === 'SHOW_RIGHT_MENU') {
        state.isVisible = true;
    }

    return state;
}

import styled from "styled-components";

export const Label = styled.div`
    background-color: #3ba900;
    font-weight: 700;
    color: #fff;
    font-size: 1rem;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    
    & > div > svg {
        color: #16e503;
        margin-left: 5px;
        cursor: pointer;
        
        &:hover {
            color: white;
        }
    }
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    background: #004a45;
    flex-direction: column;
`;

export const Loader = styled.div`
    cursor: pointer;
    height: 100px;
    border: 2px dashed white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    margin: 30px;
    
    &:hover {
        background: #3ba900;
    }
`;

export const LoaderText = styled.div`
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px;
    font-size: 1rem;
    font-weight: 700;

    @media(max-width: 992px) {
        padding: 0;
    }
`;

export const Icon = styled.div`
    font-size: 2.5rem;
`;

export const CloseIconContainer = styled.div`
    display: none;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;
`;

export const CloseIcon = styled.div`
    color: white;   
    font-size: 150px;
    display: flex;
    justify-content: center;
`;

export const Image = styled.div`
    width: 100%;
    height: 100%;
    background: url(${props => props.src});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative;
    cursor: pointer;
    
    &:hover ${CloseIconContainer} {
        display: flex;
    }
`;

export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 30px 30px 30px;
    height: 200px;
    width: auto;
    box-sizing: border-box;
    background-color: white;
`;

export const BeatLoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 40px;
`;

import React, {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {
    Content,
    Description,
    Footer,
    Image,
    LoadButton,
} from "./ExercisesTabStyles";
import {Container, Header, Tab} from "../ui/TabStyles";
import {useLoadBoard} from "../Board/hook/useLoadBoard";

export default function ExercisesTab() {
    const {i18n} = useTranslation();
    const [exercises, setExercises] = useState([]);
    const {loadBoard} = useLoadBoard();

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/exercise')
            .then(response => response.json())
            .then(exercises => setExercises(exercises));
    }, []);

    return <Tab>
            {exercises.map((exercise, key) => {
                const translations = exercise.translations.filter(translation => translation.language === i18n.language);
                const translation = translations.length > 0 ? translations[0] : null;
                return translation && <Container key={key}>
                        <Header>
                            <div>
                                <span>{translation.title}</span>
                            </div>
                            <span><LoadButton onClick={() => loadBoard(exercise.boardId)}>załaduj przykład</LoadButton></span>
                        </Header>
                        <Content>
                            <Image src={process.env.REACT_APP_CDN_URL + exercise.imageUrl}/>
                            <Description>{translation.description}</Description>
                        </Content>
                        <Footer dangerouslySetInnerHTML={{__html: translation.footer}}/>
                    </Container>
            })}
    </Tab>;
}

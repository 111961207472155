import React, {useEffect, useState} from "react";
import {
    BoardSizeToolContainer,
    ColorsToolContainer,
    Image,
    LoadImageToolContainer,
    Title,
    ToolbarContainer, ToolbarName
} from "./ToolbarStyles";
import {useTranslation} from 'react-i18next';
import BoardSizeTool from "../BoardSizeTool/BoardSizeTool";
import ColorsTool from "../ColorsTool/ColorsTool";
import LoadImageTool from "../LoadImageTool/LoadImageTool";
import {BeatLoader} from "react-spinners";

export default function Toolbar() {
    const {t} = useTranslation();
    const [palettes, setPalettes] = useState([]);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/palette')
            .then(response => response.json())
            .then(palettes => {
                setPalettes(palettes);
            });
    }, []);

    return <ToolbarContainer>
        <Title>
            <Image
                style={{display: 'none'}} // ToDo: usunąć jak będzie logo
            />
            {t('app_title_long')}
        </Title>
        <ToolbarName>
            {t('board')}
        </ToolbarName>
        <BoardSizeToolContainer>
            <BoardSizeTool/>
        </BoardSizeToolContainer>
        {palettes.length > 0
            ? palettes.map((palette, key) => <ColorsToolContainer key={key}>
                    <ColorsTool palette={palette}/>
                </ColorsToolContainer>)
            : <ColorsToolContainer>
                <BeatLoader
                    size={25}
                    color={"#004a45"}
                    loading={true}
                />
            </ColorsToolContainer>
        }
        <LoadImageToolContainer>
            <LoadImageTool/>
        </LoadImageToolContainer>
    </ToolbarContainer>;
}

import undoable, { includeAction } from 'redux-undo'
import {DEFAULT_BORD_SIZE} from "../../BoardSizeTool/BoardSizeTool";
import {DEFAULT_COLOR} from "../../BoardField/BoardField";

export const hash = (state = '', action) => {
    if (action.type === 'SET_HASH') {
        return action.hash;
    }

    return state;
};

const initialState = {
    fields: [],
    columns: DEFAULT_BORD_SIZE,
    rows: DEFAULT_BORD_SIZE,
    palette: '8'
};

const compareColors = (firstColor, secondColor) =>
    firstColor.r === secondColor.r &&
    firstColor.g === secondColor.g &&
    firstColor.b === secondColor.b;

const board = (state = initialState, action) => {
    let newState = {...state};
    let fields = [...newState.fields];
    if (action.type === 'ADD_FIELD_COLOR') {
        const fieldIndex = fields.findIndex(field => field.column === action.field.column && field.row === action.field.row);
        if (fieldIndex >= 0) {
            const actualFieldColor = fields[fieldIndex].color;
            let newColor = action.field.color;

            fields[fieldIndex].color = compareColors(actualFieldColor, newColor) && action.defaultWhenTheSame ? DEFAULT_COLOR : newColor;
        } else {
            fields.push(action.field);
        }
    }

    if (action.type === 'LOAD_IMAGE') {
        fields = [];
        let index = 0;
        action.image && action.image.forEach((column, columnIndex) => column.forEach((color, rowIndex) => {
            if (fields[index] === undefined) {
                fields[index] = {};
            }
            fields[index].color = color;
            fields[index].column = String.fromCharCode(65 + columnIndex);
            fields[index].row = rowIndex + 1;
            index++;
        }));
    }

    newState.fields = fields;

    if (action.type === 'CHANGE_BOARD_SIZE') {
        newState.columns = action.columns;
        newState.rows = action.rows;
        newState.fields = fields.filter(field =>
            field.column < String.fromCharCode(65 + newState.columns) && field.row <= newState.rows);
    }

    if (action.type === 'CLEAR_BOARD') {
        newState = initialState;
    }

    if (action.type === 'LOAD_BOARD') {
        newState.fields = action.fields;
        newState.columns = action.columns;
        newState.rows = action.rows;
        newState.palette = action.palette;
    }

    if (action.type === 'CHANGE_SELECTED_PALETTE_NAME') {
        newState.palette = action.name;
    }

    return newState;
}

export const undoableBoard = undoable(board,
    {filter: includeAction(['ADD_FIELD_COLOR', 'CHANGE_BOARD_SIZE', 'CLEAR_BOARD'])});

import React, {useState} from "react";
import {
    BottomToolbarContainer,
    ButtonsContainer,
    ToolbarContainer
} from "./BottomToolbarStyles";
import ColorsCommandsTool from "../ColorsCommandsTool/ColorsCommandsTool";
import BoardLeftActions from "../BoardLeftActions/BoardLeftActions";
import BoardRightActions from "../BoardRightActions/BoardRightActions";

export default function BottomToolbar() {
    const [isCollapsedCommand, setIsCollapsedCommand] = useState(false);

    return <BottomToolbarContainer>
        <ColorsCommandsTool onCollapseClick={() => setIsCollapsedCommand(c => !c)} isCollapsed={isCollapsedCommand}/>
        <ToolbarContainer>
            <ButtonsContainer><BoardLeftActions/></ButtonsContainer>
            <ButtonsContainer><BoardRightActions/></ButtonsContainer>
        </ToolbarContainer>
    </BottomToolbarContainer>;
}

import {combineReducers, compose, createStore} from "redux";
import {undoableBoard as board, hash} from "./components/Board/store/reducer";
import {colorsTool} from "./components/ColorsTool/store/reducer";
import {loadState, saveState} from "./components/localStorage";
import {bottomToolbar} from "./components/BottomToolbar/store/reducer";
import {rightMenu} from "./components/RightMenu/store/reducer";

const reducers = combineReducers({
    board,
    colorsTool,
    bottomToolbar,
    hash,
    rightMenu
});

const persistedState = loadState();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
    reducers,
    persistedState,
    composeEnhancers()
);

store.subscribe(() => {
    saveState({
        board: store.getState().board,
    });
});


import {DEFAULT_COLOR} from "../../BoardField/BoardField";

const initialState = {
    color: {DEFAULT_COLOR},
    palette: {
        name: "8",
        colors: []
    }
};

export const colorsTool = (state = initialState, action) => {
    let newState = {...state};
    if (action.type === 'CHANGE_SELECTED_COLOR') {
        newState.color = action.color;
    }

    if (action.type === 'CHANGE_SELECTED_PALETTE_NAME') {
        newState.palette.name = action.name;
    }

    if (action.type === 'CHANGE_SELECTED_PALETTE_COLORS') {
        newState.palette.colors = action.colors;
    }

    return newState;
}

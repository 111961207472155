import React from "react";
import {faSearchMinus, faSearchPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Buttons, ButtonText, Image} from "./BoardRightActionsStyles";
import BoardActionButton from "../BoardActionButton/BoardActionButton";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {zoom100, zoomIn, zoomOut} from "../BottomToolbar/store/actions";

export default function BoardRightActions() {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    return <Buttons>
        <BoardActionButton onClick={() => dispatch(zoomIn())}>
            <FontAwesomeIcon icon={faSearchPlus}/><ButtonText>&nbsp;{t("zoom_in")}</ButtonText>
        </BoardActionButton>
        <BoardActionButton onClick={() => dispatch(zoomOut())}>
        <ButtonText>{t("zoom_out")}&nbsp;</ButtonText><FontAwesomeIcon icon={faSearchMinus}/>
        </BoardActionButton>
        <BoardActionButton className="wider" onClick={() => dispatch(zoom100())}>100%</BoardActionButton>
        <Image className="first">
            <a href="https://edu-sense.com/pl?utm_source=puzzle&utm_medium=footer  ">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 31 34.5" style={{"enableBackground": "new 0 0 31 34.5"}}
                     xmlSpace="preserve">
                    <style type="text/css">
                        {
                            '.st0{fill:#FFFFFF;}'
                        }
                    </style>
                    <path style={{"fill": "#00458a"}} className="st0" d="M26.3,25.7h-7.9h-0.2H9.7v-4h10.4c2.4,0,4.3-1.9,4.3-4.3c0-2.4-1.9-4.3-4.3-4.3H9.7V8.8h10.3
	                                c2.4,0,4.3-1.9,4.3-4.3c0-2.4-1.9-4.3-4.3-4.3h-15c-2.5,0-4.6,2-4.7,4.5l0,0.1c0,0,0,0.1,0,0.1v24.8c0,0,0,0.1,0,0.1l0,0.1
	                                c0,0,0,0.1,0,0.2l0,0.1c0.2,2.3,2,4,4.2,4.2c0.2,0,0.3,0,0.4,0h13.1h0.2h7.9c2.4,0,4.3-1.9,4.3-4.3C30.6,27.6,28.7,25.7,26.3,25.7z
	                                 M9.7,19.7V15l10.4,0c1.3,0,2.4,1.1,2.4,2.4s-1.1,2.4-2.4,2.4H9.7z M14.6,32.4H8.8c0.6-0.8,0.9-1.8,0.9-2.7v-2h4.9
	                                c-0.5,0.7-0.7,1.5-0.7,2.4C13.9,30.8,14.1,31.7,14.6,32.4z M8.8,2.1h11.2c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4H9.7v-2
	                                C9.7,3.9,9.4,2.9,8.8,2.1z M22.7,30c0-0.8-0.3-1.7-0.7-2.4h4.3c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4H22
	                                C22.4,31.7,22.7,30.8,22.7,30z M18.2,27.6h0.2c0.6,0,1.2,0.2,1.7,0.7c0.5,0.5,0.7,1.1,0.7,1.7c0,1.3-1.1,2.4-2.4,2.4h-0.2
	                                c-1.3,0-2.4-1.1-2.4-2.4C15.8,28.7,16.9,27.6,18.2,27.6z M2.3,17.4C2.3,16,3.4,15,4.7,15h3.1v4.8H4.7C3.4,19.7,2.3,18.7,2.3,17.4z
	                                 M2.3,20.9c0.7,0.5,1.5,0.7,2.4,0.7h3.1v4H4.7c-0.8,0-1.7,0.3-2.4,0.8V20.9z M2.3,4.6c0.1-1.4,1.3-2.5,2.7-2.5
	                                c1.5,0,2.7,1.2,2.7,2.7v2H4.7C3.5,6.9,2.4,5.9,2.3,4.6z M2.3,8.1c0.7,0.5,1.6,0.8,2.4,0.8h3.1V13H4.7c-0.8,0-1.7,0.3-2.4,0.7V8.1z
	                                 M4.7,27.6h3.1v2c0,1.5-1.2,2.7-2.7,2.7c-0.6,0-1.3-0.2-1.8-0.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.5-0.6-1-0.7-1.6
	                                C2.4,28.6,3.5,27.6,4.7,27.6z"/>
                </svg>
                <span className="icon_padding">Edu-sense</span>
            </a>
        </Image>
        <Image>
            <a href="https://akademia.edu-sense.com/pl?utm_source=puzzle&utm_medium=footer   ">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 31 34.5" style={{"enableBackground": "new 0 0 31 34.5"}}
                     xmlSpace="preserve">
                    <style type="text/css">
                        {
                            '.st0{fill:#FFFFFF}'
                        }
                    </style>
                    <path style={{"fill": "#00458a"}} className="st0" d="M30,27.4L21.7,4.5c-1-2.9-2.3-4.3-6.3-4.3c-3.6,0-5.1,1.1-6.3,4.4L1,27.4c0,0,0,0.1,0,0.1
	                                c-0.2,0.7-0.4,1.4-0.4,2.2c0,2.7,2.1,4.7,4.9,4.7c2.4,0,4.1-1.3,4.8-3.6c0,0,0,0,0,0l0.9-2.8c2.6,0,5.9,0,8.5,0l0.9,2.8
	                                c1,3,3.2,3.6,4.8,3.6c2.2,0,4-1.2,4.7-3.2c0.2-0.5,0.2-1,0.2-1.5C30.4,28.9,30.2,28.2,30,27.4z M11,5.2c0.8-2.4,1.5-3.1,4.4-3.1
	                                c3,0,3.6,0.8,4.4,3l5.1,14.1c-0.2,0-0.5-0.1-0.7-0.1c0,0-2.3,0-5.2,0L15.4,8.4l0,0.1L12,19.2c-2.6,0-4.7,0-5.2,0
	                                c-0.3,0-0.5,0-0.7,0.1L11,5.2z M16.9,19.2c-0.9,0-1.9,0-2.8,0l1.4-4.3L16.9,19.2z M8.5,30.1C8.5,30.1,8.5,30.1,8.5,30.1
	                                C8,31.7,7,32.3,5.5,32.3c-1.8,0-2.9-1.1-2.9-2.7c0-0.5,0.1-1,0.3-1.5c0,0,0,0,0,0l0.6-1.8c0.7,1,1.9,1.7,3.2,1.7c0.4,0,1.3,0,2.4,0
	                                L8.5,30.1z M19,25.9h-7.1H9.8h-3c-1.1,0-2-1-2-2.3c0-1.3,0.9-2.3,2-2.3h4.5h2.1h4.1h2.1h4.6c1.1,0,2,1,2,2.3c0,1.3-0.9,2.3-2,2.3h-3
	                                H19z M28.2,30.6c-0.4,1.1-1.4,1.8-2.8,1.8c-1.5,0-2.4-0.7-2.9-2.2L21.8,28c1.4,0,2.4,0,2.4,0c1.3,0,2.5-0.7,3.2-1.7l0.7,1.8
	                                c0.2,0.5,0.3,1,0.3,1.6C28.3,30,28.3,30.3,28.2,30.6z"/>
                </svg>
                <span className="icon_padding"
                >Akademia</span>
            </a>
        </Image>
    </Buttons>;
}

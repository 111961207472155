import styled from "styled-components";

export const BoardSizeToolContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #004a45;
    padding: 0 20px;
    color: #fff;
`;

export const Row = styled.div`
    height: 70px;
    width: 100%;
    display: flex;
    
    & span {
        font-size: 1rem;
    }
`;

export const SliderWrapper = styled.div`
    width: 100%;
`;

export const SliderValue = styled.input`
    width: 30px;
    height: 30px;
    margin-left: 20px;
    font-size: 1rem;
    text-align: center;
`;

export const SliderValueWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

import styled from "styled-components";

const BoardActionButton = styled.div`
    color: white;
    line-height: 75px;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    padding: 0 20px;
    font-size: 12px;
    font-weight: 700;
    min-width: 70px;
    white-space: nowrap;
    text-align: center;
    
    &:hover {
        ${props => !props.disabled && 'background: #00458a;'}
    }
    
    ${props => props.disabled && 'opacity: 0.7;'}

    @media (max-width: 1150px) {
        min-width: 1rem;
        &.wider {
            min-width: 2rem;
        }
    }

    @media (max-width: 768px) {
        line-height: 1.5rem;
    }
`;

export default BoardActionButton;

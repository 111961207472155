import styled from "styled-components";
import {BoardColumn} from "../Board/BoardStyles";

const FIELD_SIZE = 30;

export const Field = styled.div`
    width: ${props => props.scale * FIELD_SIZE}px;
    height: ${props => props.scale * FIELD_SIZE}px;
    border: 1px solid transparent;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    background-color: ${props => props.color && 'rgb(' + props.color.r + ',' + props.color.g + ',' + props.color.b + ')'};
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
    ${BoardColumn}:nth-of-type(2) & {
        border-left: 1px solid gray;
    }
    
    &:nth-of-type(2) {
        border-top: 1px solid gray;
    }
`;

export const FieldLabel = styled(Field)`
    border: 1px solid transparent !important;
    line-height: ${props => props.scale * FIELD_SIZE + 2}px;
    text-align: center;
    font-size: ${props => props.scale}rem;
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

import styled from "styled-components";

export const WorkingSpaceContainer = styled.div`
    position: relative;
    overflow: hidden;
    border: 1px solid #000;
    width: 100%;
    background: #e8e8e8;
    display: flex;
    justify-content: center;
}
`;

export const BoardCenter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 75px;
`;

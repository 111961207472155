import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEn from './locales/en/translation.json';
import translationPl from './locales/pl/translation.json';

const resources = {
    en: {translation: translationEn},
    pl: {translation: translationPl}
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        language: 'pl', //ToDo do usunięcia gdy będzie miał być angielski właczony
        fallbackLng: 'pl',
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ["path", "localStorage", "navigator"],
            lookupFromPathIndex: 0,
            caches: ["localStorage"]
        }
    });

export default i18n;

import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    CopyButton,
    ExportTabContainer,
    Input, LoaderContainer,
    PrintButtons,
    Tab,
    Tooltip,
    TooltipContainer
} from "../ui/TabStyles";
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {BeatLoader} from "react-spinners";

export default function ExportTab() {
    const {t} = useTranslation();
    const [puzzleUrl, setPuzzleUrl] = useState(document.location.href);
    const hash = useSelector(state => state.hash);
    const input = useRef(null);
    const [tooltipText, setTooltipText] = useState(t('copy_to_clipboard'));

    useEffect(() => {
        setPuzzleUrl(document.location.href);
    }, [setPuzzleUrl, hash])

    const copyToClipboard = () => {
        input.current.select();
        document.execCommand("copy");
        setTooltipText(t('copied_to_clipboard'));
    }

    const outFunction = () => {
        setTooltipText(t('copy_to_clipboard'));
    }

    return <Tab>
        {hash ? <ExportTabContainer>
            <h4>{t('link_to_your_board')}</h4>
            <TooltipContainer>
                <Input ref={input} onFocus={e => e.target.select()} type="text" value={puzzleUrl} readOnly/>
                <CopyButton onClick={copyToClipboard} onMouseOut={outFunction}>
                    {t('copy')}
                </CopyButton>
                <Tooltip>{tooltipText}</Tooltip>
            </TooltipContainer>

            <PrintButtons>
                <a href={`${process.env.REACT_APP_PDF_URL}/0/${hash}`}>
                    <Button>{t('print_empty_board')}</Button>
                </a>
                <a href={`${process.env.REACT_APP_PDF_URL}/1/${hash}`}>
                    <Button>{t('print_board')}</Button>
                </a>
            </PrintButtons>
        </ExportTabContainer> : <LoaderContainer>
            <div>
                <BeatLoader
                    size={25}
                    color={"#00458a"}
                    loading={true}
                />
            </div>
        </LoaderContainer>}
    </Tab>;
}

import styled from "styled-components";
import {Button} from "../ui/TabStyles";

export const Image = styled.div`
    width: 300px;
    height: 300px;
    background: url(${props => props.src}) no-repeat; 
    background-size: cover;
`;

export const LoadImageButton = styled(Button)`
    margin-top: 20px;
    width: 300px;
`;

export const LoadImageContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 40px;
`;

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { CloseButton, ModalContent, Text } from "./AlertWindowStyles";

const AlertWindow = () => {
	const { t } = useTranslation();
	const [modalIsOpen, setIsOpen] = React.useState(false);

	const customStyles = {
		content: {
			top: '30%',
			left: '30%',
			right: 'auto',
			bottom: 'auto',
			transform: 'translate(-15%, 0)',
			backgroundColor: '#f60',
		}
	};

	useEffect(() => {
		if (window.innerWidth < 992) {
			setIsOpen(true);
		}
	}, []);

	function closeModal() {
		setIsOpen(false);
	}

	return (
		<div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				ariaHideApp={false}
				style={customStyles}
			>
				<ModalContent>
					<CloseButton onClick={closeModal}>
						<FontAwesomeIcon icon={faTimes} />
					</CloseButton>
					<Text>{t('too-small-window')}</Text>
				</ModalContent>
			</Modal>
		</div>
	);
}

export default AlertWindow;
import styled from "styled-components";

export const CommandsTool = styled.div`
    position: relative;
    width: 100%;
    background: #004a45;
    display: block;
    box-sizing: border-box;
    overflow: hidden;
`;

export const Title = styled.div`
    color: white;
    font-weight: 700;
    margin: 10px 0;
    padding-left: 20px;
    text-transform: uppercase;
`;

export const IconContainer = styled.div`
    color: white;
    position: absolute;
    width: 100%;
    text-align: center;
    margin-top: 0;
    font-size: 25px;
    cursor: pointer;
`;

export const ScrollContainer = styled.div`
    height: 120px;
    overflow: ${props => props.isCollapsed ? 'hidden' : 'auto'};
    height: ${props => props.isCollapsed ? '40px' : '120px'};
    -webkit-transition: height .7s;
    -o-transition: height .7s;
    transition: height .7s;
`;

export const ColorsContainer = styled.div`
    padding: 0 20px 10px 20px;
`;

export const Color = styled.div`
    height: 20px;
    width: 20px;
    min-width: 20px;
    background-color: ${props => 'rgb(' + props.color.r + ',' + props.color.g + ',' + props.color.b + ')'};
`;

export const ColorWrapper = styled.div`
    margin-bottom: 10px;
    display: flex;
`;

export const FieldsList = styled.div`
    display: flex;
    margin-left: 10px;
    line-height: 20px;
    color: white;
`;

import React from "react";
import {Buttons} from "./BoardLeftActionsStyles";
import {faRedo, faUndo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BoardActionButton from "../BoardActionButton/BoardActionButton";
import {useTranslation} from "react-i18next";
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import {useDispatch, useSelector} from "react-redux";
import {clearBoard} from "../Board/store/actions";
import {useHash} from "../Board/hook/useHash";
import { ButtonText } from "../BoardRightActions/BoardRightActionsStyles";

export default function BoardLeftActions() {
    const {past, future} = useSelector(state => state.board);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {clearHash} = useHash();

    return <Buttons>
        <BoardActionButton onClick={() => past.length > 0 && dispatch(UndoActionCreators.undo()) && clearHash()}
                           disabled={past.length <= 0}>
            <FontAwesomeIcon icon={faUndo}/><ButtonText>&nbsp;{t('undo')}</ButtonText>
        </BoardActionButton>
        <BoardActionButton onClick={() => future.length > 0 && dispatch(UndoActionCreators.redo()) && clearHash()}
                           disabled={future.length <= 0}>
            <ButtonText>{t('redo')}&nbsp;</ButtonText><FontAwesomeIcon icon={faRedo}/>
        </BoardActionButton>
        <BoardActionButton onClick={() => dispatch(clearBoard()) && clearHash()}>
            {t('clear')}
        </BoardActionButton>
    </Buttons>;
}

import styled from "styled-components";

export const RightMenuContainer = styled.div`
    position: absolute;
    right: 0;
    top: 55px;
    width: auto;
    display: flex;
`;

export const RightMenuTabsButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    right: 0;
    top: 30px;
    position: absolute;
    left: -160px;
    overflow: hidden;
`;

export const RightMenuTabButton = styled.div`
    cursor: pointer;
    position: relative;
    width: 140px;
    max-height: 25px;
    display: block;
    -webkit-transition: opacity .7s linear;
    -o-transition: opacity .7s linear;
    -webkit-transition: opacity .7s linear;
    -webkit-transition: opacity .7s linear;
    transition: opacity .7s linear;
    text-align: center;
    background: ${props => props.selected ? '#16a903' : '#00458a'};
    color: #fff;
    border: 2px solid #fff;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    margin-bottom: 1px;
    padding: 5px 10px;
    border-right: unset;
    text-align: left;
    
    &:hover {
        background-color: #16a903;
    }
`;

export const RightMenuTabContainer = styled.div`
    height: 50vh;
    width: ${props => props.show ? '30vw' : '0'};
    overflow: hidden;
    overflow-y: auto;
    right: 0;
    font-family: Roboto,sans-serif;
    background: #16a903;
    border-top: 2px solid #fff;
    border-left: ${props => props.show ? '2px' : '0'} solid #fff;
    border-bottom: 2px solid #fff;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    -webkit-transition: width .7s, border .7s  0.2s;
    -o-transition: width .7s, border .7s  0.2s;
    transition: width .7s, border .7s  0.2s;
    font-weight: 700;
    z-index: 1;
`;

import styled from "styled-components";
import {Button} from "../ui/TabStyles";

export const Content = styled.div`
    display: flex;
    margin: 20px;
`;

export const Image = styled.div`
    min-width: 200px;
    height: 200px;
    background: url(${props => props.src}) no-repeat; 
    background-size: contain;
`;

export const Description = styled.div`
    margin-left: 20px;
`;

export const Footer = styled.div`
    margin: 20px;
`;

export const LoadButton = styled(Button)`
    background: #004a45;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 5px;
    border: 1px solid #fff;
    
    &:hover {
        background: white;    
        color: #16a903;
    }
`;

import React from "react";
import BottomToolbar from "../BottomToolbar/BottomToolbar";
import {BoardCenter, WorkingSpaceContainer} from "./WorkingSpaceStyles";
import BoardWrapper from "../BoardWrapper/BoardWrapper";
import {hideRightMenu} from "../RightMenu/store/actions";
import {useDispatch} from "react-redux";
import AlertWindow from "../AlertWindow/AlertWindow";

export default function WorkingSpace() {
    const dispatch = useDispatch();
    const hide = () => dispatch(hideRightMenu());

    return <WorkingSpaceContainer onClick={() => hide()}>
        <BoardCenter>
            <AlertWindow />
            <BoardWrapper/>
        </BoardCenter>
        <BottomToolbar/>
    </WorkingSpaceContainer>;
}

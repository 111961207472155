import React, {useEffect, useState} from "react";
import {
    Color, ColorsContainer, ColorsLabel,
    ColorsToolContainer
} from "./ColorsToolStyles";
import {useDispatch, useSelector} from "react-redux";
import {changeSelectedColor, changeSelectedPaletteColors} from "./store/actions";
import {useTranslation} from "react-i18next";
import {changeSelectedPaletteName} from "../Board/store/actions";
import {useHash} from "../Board/hook/useHash";
import {faInfoCircle, faPlus, faMinus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { showRightMenu } from "../RightMenu/store/actions";

export default function ColorsTool({palette}) {
    const [selectedColor, setSelectedColor] = useState();
    const {palette: paletteIndex} = useSelector(state => state.board.present);
    const dispatch = useDispatch();
    const {i18n} = useTranslation();
    const {clearHash} = useHash();
    const colors = palette.colors;

    useEffect(() => {
        setSelectedColor(colors && colors[0]);
    }, [colors]);

    useEffect(() => {
        if (paletteIndex === palette.name) {
            dispatch(changeSelectedColor(selectedColor));
            dispatch(changeSelectedPaletteColors(colors));
        }
    }, [dispatch, selectedColor, palette, paletteIndex, colors])

    const translations = palette.translations.filter(translation => translation.language === i18n.language);
    const translation = translations.length > 0 ? translations[0] : null;

    const show = () => dispatch(showRightMenu());

    return <ColorsToolContainer>
        <ColorsLabel onClick={() => {
            dispatch(changeSelectedPaletteName(palette.name));
            dispatch(changeSelectedPaletteColors(colors));
            clearHash();
        }}>
            <div>{translation && translation.title}</div>
            <div>
                <FontAwesomeIcon icon={faInfoCircle} onClick={() => show()}/>
                <FontAwesomeIcon icon={paletteIndex !== palette.name ? faPlus : faMinus}/>
            </div>
        </ColorsLabel>
        <ColorsContainer collapsed={paletteIndex !== palette.name}>
            {colors && colors.map((color, i) =>
                <Color key={i} color={color} selected={color === selectedColor} onClick={() => {
                    setSelectedColor(color);
                    dispatch(changeSelectedColor(color));
                }}/>)}
        </ColorsContainer>
    </ColorsToolContainer>;
}

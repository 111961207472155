import styled from "styled-components";

export const BottomToolbarContainer = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const ToolbarContainer = styled.div`
    height: 75px;
    width: 100%;
    background: #16a903;
    display: flex;
    justify-content: space-between;

    @media(max-width: 768px) {
        flex-wrap: wrap;
    }
`;

export const ButtonsContainer = styled.div`
    width: 35%;

    @media(max-width: 768px) {
        width: 100%;
    }
`;

export const ColorsCommandsButton = styled.div`
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
    line-height: 75px;
    padding: 0 20px;
    
    &:hover {
        background: #00458a;
    }
`;

import styled from "styled-components";

export const ToolbarContainer = styled.div`
    width: 355px;
    min-width: 355px;
    background: #004a45;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    @media(max-width: 992px) {
        width: 30%;
        min-width: 180px;
    }
`;

export const Image = styled.div`
    height: 40px;
    width: 40px;
    background: gray;
    margin-right: 8px;
    box-sizing: border-box;
    border: 2px solid black;
`;

export const Row = styled.div`
    height: 40px;
    line-height: 40px;
    font-size: 1.5rem;
    display: flex;
    padding: 0 20px;
    border-bottom: 1px solid #139203;
    background-color: #16a903;

    @media(max-width: 992px) {
        white-space: normal !important;
        font-size: 1rem;
        height: auto;
        line-height: 1.1rem;
    }
`;

export const Title = styled(Row)`
    background-color: #00458a;
    color: #fff;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 20px;

    @media(max-width: 992px) {
        overflow: unset;
        padding: 20px;
    }
`;

export const ToolbarName = styled(Row)`
    text-transform: uppercase;
    background-color: #3ba900;
    padding: 0 20px;
    color: #fff;
    font-weight: 700;

    @media(max-width: 992px) {
        padding: 20px;
    }
`;

export const BoardSizeToolContainer = styled(Row)`
    height: auto;
    padding: 0;
`;

export const ColorsToolContainer = styled(Row)`
    height: auto;
    padding: 0;
    flex-direction: column;
    
    & .css-0 {
        text-align: center;
        padding: 10px 0 0 0;
    }

    @media(max-width: 992px) {
        background-color: #3ba900;
        padding: 10px 0 0 0;
    }
`;

export const LoadImageToolContainer = styled(Row)`
    height: auto;
    padding: 0;
    flex-direction: column;
`;

import {MAX_SCALE, MIN_SCALE} from "../../Board/Board";

const ZOOM_STEP = 2;

const initialState = {
    scale: 1
};

export const bottomToolbar = (state = initialState, action) => {
    let newState = {...state};
    if (action.type === 'ZOOM_IN') {
        if (newState.scale < MAX_SCALE) {
            let scale = newState.scale * 10;
            scale += ZOOM_STEP;
            newState.scale = scale / 10;
        }
    }

    if (action.type === 'ZOOM_OUT') {
        if (newState.scale > MIN_SCALE) {
            let scale = newState.scale * 10;
            scale -= ZOOM_STEP;
            newState.scale = scale / 10;
        }
    }

    if (action.type === 'ZOOM_100') {
        newState.scale = 1;
    }

    return newState;
}

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import React from "react";
import {
    BoardSizeToolContainer,
    Row,
    SliderValue,
    SliderValueWrapper,
    SliderWrapper
} from "./BoardSizeToolStyles";
import Slider from 'rc-slider';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {changeBoardSize} from "../Board/store/actions";
import {useHash} from "../Board/hook/useHash";

export const MIN_BOARD_SIZE = 5;
export const MAX_BOARD_SIZE = 26;
export const DEFAULT_BORD_SIZE = 10;

export default function BoardSizeTool() {
    const {present} = useSelector(state => state.board);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {clearHash} = useHash();

    return <BoardSizeToolContainer>
        <Row>
            <SliderWrapper>
                <span>{t('columns_count')}</span>
                <Slider min={MIN_BOARD_SIZE}
                        max={MAX_BOARD_SIZE}
                        value={present.columns}
                        onChange={value => {
                            dispatch(changeBoardSize(value, present.rows));
                            clearHash();
                        }}/>
            </SliderWrapper>
            <SliderValueWrapper>
                <SliderValue value={present.columns} disabled/>
            </SliderValueWrapper>
        </Row>
        <Row>
            <SliderWrapper>
                <span>{t('rows_count')}</span>
                <Slider min={MIN_BOARD_SIZE}
                        max={MAX_BOARD_SIZE}
                        value={present.rows}
                        onChange={value => {
                            dispatch(changeBoardSize(present.columns, value));
                            clearHash();
                        }}/>
            </SliderWrapper>
            <SliderValueWrapper>
                <SliderValue value={present.rows} disabled/>
            </SliderValueWrapper>
        </Row>
    </BoardSizeToolContainer>;
}

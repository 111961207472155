import React from "react";
import Board from "../Board/Board";
import {useSelector} from "react-redux";

export default function BoardWrapper() {
    const {present} = useSelector(state => state.board);
    const {columns, rows} = present;

    return <Board columns={columns} rows={rows}/>
}

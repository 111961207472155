import React from "react";
import {
    Color,
    ColorsContainer,
    ColorWrapper,
    CommandsTool,
    FieldsList,
    IconContainer, ScrollContainer,
    Title
} from "./ColorsCommandsToolStyles";
import {useSelector} from "react-redux";
import { faAngleDoubleDown, faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {BeatLoader} from "react-spinners";

export default function ColorsCommandsTool({className, onCollapseClick, isCollapsed}) {
    const colors = useSelector(state => state.colorsTool.palette.colors);
    const {present} = useSelector(state => state.board);
    const {fields} = present;
    const {t} = useTranslation();

    const getFieldsForColor = color => {
        const fieldsForColor = fields.filter(field => field.color.r === color.r && field.color.g === color.g && field.color.b === color.b);
        return fieldsForColor.map(field => field.column + field.row + ' ')
    };

    return <CommandsTool className={className}>
        <IconContainer onClick={onCollapseClick}>
            {isCollapsed
                ? <FontAwesomeIcon icon={faAngleDoubleUp}/>
                : <FontAwesomeIcon icon={faAngleDoubleDown}/>
            }
        </IconContainer>
        <ScrollContainer isCollapsed={isCollapsed}>
            <Title>{t('coded_colors')}</Title>
            <ColorsContainer>
                {colors.length > 0 ? colors.map((color, index) => {
                    const fields = getFieldsForColor(color);
                    if (fields.length === 0) {
                        return null;
                    }

                    return <ColorWrapper key={index}>
                        <Color color={color}/>
                        <FieldsList>{fields}</FieldsList>
                    </ColorWrapper>;
                }) :
                    <BeatLoader
                        size={25}
                        color={"#3ba900"}
                        loading={true}
                    />
                }
            </ColorsContainer>
        </ScrollContainer>
    </CommandsTool>;
}

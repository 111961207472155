import {setHash} from "../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useEffect} from "react";

export function useHash() {
    const dispatch = useDispatch();
    const {hash: hashFromParams} = useParams();
    const hashFromStore = useSelector(state => state.hash);

    const clearHash = () => {
        dispatch(setHash(null));
        window.hash = null;
    };

    useEffect(() => {
        let url = document.location.href.split('/');
        if (url[url.length - 1] === "") {
            url.splice(-1, 1);
        }
        if (url[url.length - 1].match(new RegExp('^[a-z0-9]{8}$', 'gi'))) {
            url.splice(-1, 1);
        }
        url.push(hashFromStore);
        url = url.join('/');
        window.history.pushState(null, null, url);
        window.hash = hashFromStore;
    }, [hashFromStore]);

    return {
        hashFromParams,
        clearHash,
        setHash: hash => {
            dispatch(setHash(hash));
            window.hash = hash;
        }
    };
}

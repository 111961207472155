import {loadBoard} from "../store/actions";
import {useDispatch} from "react-redux";

export const useLoadBoard = () => {
    const dispatch = useDispatch();

    const load = hash => fetch(process.env.REACT_APP_API_URL + '/board/' + hash)
        .then(response => response.json())
        .then(response => {
            if (response.id === hash) {
                const data = response.data;
                dispatch(loadBoard(data.fields, data.columns, data.rows, data.palette));
            }
        });

    return {
        loadBoard: load
    };
};

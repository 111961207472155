import React from "react";
import {Field} from "./BoardFieldStyles";
import {useSelector} from "react-redux";

export const DEFAULT_COLOR = {r: 255, g: 255, b: 255};

export default function BoardField({column, row, scale = 1, onMouseDown = () => {}, onMouseOver = () => {}}) {
    const {present: board} = useSelector(state => state.board);
    const field = board.fields && board.fields.find(field => field.column === column && field.row === row);
    const color = field && field.color;

    return <Field color={color || DEFAULT_COLOR}
                  onMouseOver={onMouseOver}
                  onMouseDown={onMouseDown}
                  colmn={column}
                  row={row}
                  scale={scale}
    />;
}

import React, {useState} from "react";
import {
    RightMenuContainer,
    RightMenuTabButton,
    RightMenuTabContainer,
    RightMenuTabsButtonsContainer
} from "./RightMenuStyles";
import ExportTab from "../ExportTab/ExportTab";
import ExercisesTab from "../ExercisesTab/ExercisesTab";
import LastCreatedTab from "../LastCreatedTab/LastCreatedTab";
import HelpTab from "../HelpTab/HelpTab";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {setHash} from "../Board/store/actions";
import {hideRightMenu, showRightMenu} from "./store/actions";

export default function RightMenu() {
    const [currentTabName, setCurrentTabName] = useState('help');
    const {t} = useTranslation();
    const availableTabs = {
        'export': <ExportTab/>,
        'exercises': <ExercisesTab/>,
        'lastCreated': <LastCreatedTab/>,
        'help': <HelpTab/>,
    };
    const {present} = useSelector(state => state.board);
    const dispatch = useDispatch();
    const isVisible = useSelector(state => state.rightMenu.isVisible);
    const show = () => dispatch(showRightMenu());
    const hide = () => dispatch(hideRightMenu());

    const postBoard = () => fetch(process.env.REACT_APP_API_URL + '/board', {
        method: 'POST',
        body: JSON.stringify(present)
    })
        .then(response => response.json())
        .then(hash => dispatch(setHash(hash)));

    const toggleExportTab = () => {
        if (isVisible === false || currentTabName !== 'export') {
            show();
            setCurrentTabName('export');
            postBoard().then();
        } else {
            hide();
        }
    };

    const toggleTab = tabName => {
        if (isVisible === false || currentTabName !== tabName) {
            show();
            setCurrentTabName(tabName);
        } else {
            hide();
        }
    };

    const renderTab = () => {
        return availableTabs[currentTabName];
    };

    return <RightMenuContainer>
        <RightMenuTabsButtonsContainer>
            <RightMenuTabButton selected={isVisible && currentTabName === 'export'}
                                onClick={() => toggleExportTab()}>{t('export')}</RightMenuTabButton>
            <RightMenuTabButton selected={isVisible && currentTabName === 'exercises'}
                                onClick={() => toggleTab('exercises')}>{t('exercises')}</RightMenuTabButton>
            <RightMenuTabButton selected={isVisible && currentTabName === 'lastCreated'}
                                onClick={() => toggleTab('lastCreated')}>{t('last_created')}</RightMenuTabButton>
            <RightMenuTabButton selected={isVisible && currentTabName === 'help'}
                                onClick={() => toggleTab('help')}>{t('help')}</RightMenuTabButton>
        </RightMenuTabsButtonsContainer>
        <RightMenuTabContainer show={isVisible}>
            {renderTab()}
        </RightMenuTabContainer>
    </RightMenuContainer>;
}

import React, {useEffect, useState} from "react";
import {Container, Tab} from "../ui/TabStyles";
import {useLoadBoard} from "../Board/hook/useLoadBoard";
import {LoadImageButton, Image, LoadImageContainer} from "./LastCreatedTabStyles";

export default function LastCreatedTab() {
    const [boards, setBoards] = useState([]);
    const {loadBoard} = useLoadBoard();

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/board/newest/10')
            .then(response => response.json())
            .then(boards => setBoards(boards));
    }, []);

    return <Tab>
        {boards.map((board, key) => <LoadImageContainer key={key}>
            <Container>
                <Image src={process.env.REACT_APP_CDN_URL + board.previewUrl}/>
                <LoadImageButton onClick={() => loadBoard(board.id)}>załaduj planszę</LoadImageButton>
            </Container>
        </LoadImageContainer>)}
    </Tab>;
}

import styled from "styled-components";

export const Tab = styled.div`
    width: 30vw;
    height: 100%;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    background: #004a45;
    padding: 1rem;
    
    & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

export const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    
    & > div {
        text-align: center;
    }
`;

export const Container = styled.div`
    color: white;
`;

export const ExportTabContainer = styled.div`
    padding: 35px 40px;
    
    h4 {
        margin: 0;
        color: #00458a;
        font-size: 18px;
        font-weight: 700;
    }
`;

export const Tooltip = styled.span`
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    top: 18px;
    right: 0;
    margin-right: 10px;
    transition: opacity 0.3s;
    font-size: 12px;
    
    &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
    }
`;

export const TooltipContainer = styled.div`
    display: flex;
    
    &:hover ${Tooltip} {
        visibility: visible;
        opacity: 1 !important;
    }
`;

export const Button = styled.button`
    width: 100%;
    cursor: pointer;
    background: #143a67;
    color: white;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 12px;
    padding: 15px 20px;
    text-decoration: none;
    border: none;
    
    &:hover {
        background: $light-green;
        color: #fff;
        -webkit-box-shadow: inset 0 0 0 2px #fff;
        -moz-box-shadow: inset 0 0 0 2px #fff;
        box-shadow: inset 0 0 0 2px #fff;
    }
`;

export const CopyButton = styled(Button)`
    width: 90px;
    z-index: 1;
`;

export const Input = styled.input`
    width: 100%;
    text-indent: 0.5rem;
    padding: 10px 10px 10px 0;
    position: relative;
    border: 2px solid white;
`;

export const PrintButtons = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    
    & ${Button} {
        margin-bottom: 10px;
    }
`;

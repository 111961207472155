import React, {useEffect, useState} from "react";
import {Container, Header, Tab} from "../ui/TabStyles";
import {useTranslation} from 'react-i18next';
import {Content} from "./HelpTabStyles";

export default function HelpTab() {
    const {i18n} = useTranslation();
    const [palettes, setPalettes] = useState([]);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/palette/help')
            .then(response => response.json())
            .then(palettes => {
                setPalettes(palettes);
            });
    }, []);


    return <Tab>
        <Container>
            <Header>wgrywanie zdjęć</Header>
            <Content>
                Załaduj tutaj zdjęcie
            </Content>
        </Container>
        {palettes.map(palette => {
            const translations = palette.translations.filter(translation => translation.language === i18n.languages[0]);
            const translation = translations.length > 0 ? translations[0] : null;

            return translation && <Container key={translation.title}>
                <Header>{translation.title}</Header>
                <Content>{translation.help}
                </Content>
            </Container>
        })}
    </Tab>;
}

import React, {useEffect} from 'react';
import Toolbar from "../Toolbar/Toolbar";
import WorkingSpace from "../WorkingSpace/WorkingSpace";
import {AppContainer} from "./AppStyles";
import RightMenu from "../RightMenu/RightMenu";
import {useDispatch} from "react-redux";
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {useTranslation} from "react-i18next";

function App() {
    const dispatch = useDispatch();
    const {i18n} = useTranslation();

    useEffect(() => {
        dispatch(UndoActionCreators.clearHistory())
    }, [dispatch]);

    return (
        <BrowserRouter>
            <AppContainer className="App">
                <Toolbar/>
                <Switch>
                    <Redirect exact from="/" to={`/${i18n.language}`}/>
                    <Route exact path="/*/:hash" render={props => <WorkingSpace {...props}/>}/>
                    <Route exact path="/*" render={props => <WorkingSpace {...props}/>}/>
                </Switch>
                <RightMenu/>
            </AppContainer>
        </BrowserRouter>
    );
}

export default App;

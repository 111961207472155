import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    BoardColumn,
    BoardContainer,
} from "./BoardStyles";
import BoardField from "../BoardField/BoardField";
import {FieldLabel} from "../BoardField/BoardFieldStyles";
import {addFieldColor} from "./store/actions";
import {useHash} from "./hook/useHash";
import {useLoadBoard} from "./hook/useLoadBoard";

export const MAX_SCALE = 2;
export const MIN_SCALE = 0.5;

export default function Board({columns, rows}) {
    const selectedColor = useSelector(state => state.colorsTool.color);
    const dispatch = useDispatch();
    const scale = useSelector(state => state.bottomToolbar.scale);
    const {hashFromParams: hash, clearHash, setHash} = useHash();
    const {loadBoard} = useLoadBoard();

    useEffect(() => {
        if (!hash) return;
        setHash(hash);
        loadBoard(hash).then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash]);

    const [mouseDown, setMouseDown] = useState(false);

    useEffect(() => {
        const handleMouseDown = () => {
            setMouseDown(true);
        };
        const handleMouseUp = () => {
            setMouseDown(false);
        };
        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mouseup', handleMouseUp);
        }
    }, []);

    const changeFieldColor = (column, row, defaultWhenTheSame = true) => {
        dispatch(addFieldColor(column, row, selectedColor, defaultWhenTheSame));
        clearHash();
    };

    const renderFields = () => [...(new Array(columns + 1))].map((e, i) => {
            const column = String.fromCharCode(64 + i)
            return i === 0
                ? <BoardColumn key={i}>
                    <FieldLabel scale={scale}/>
                    {[...(new Array(rows))].map((e, j) => <FieldLabel scale={scale} key={j}>{j + 1}</FieldLabel>)}
                </BoardColumn>
                : <BoardColumn key={i}>
                    <FieldLabel scale={scale}>{column}</FieldLabel>
                    {[...(new Array(rows))].map((e, j) =>
                        <BoardField
                            onMouseOver={() => mouseDown && changeFieldColor(column, j + 1, false)}
                            onMouseDown={() => changeFieldColor(column, j + 1)}
                            key={j}
                            scale={scale}
                            column={column}
                            row={j + 1}
                        />)}
                </BoardColumn>
        }
    );

    return <BoardContainer>
            {renderFields()}
    </BoardContainer>;
}

import React, {useCallback, useState} from "react";
import {loadImage} from "../Board/store/actions";
import {useDispatch, useSelector} from "react-redux";
import {useHash} from "../Board/hook/useHash";
import {useDropzone} from "react-dropzone";
import {
    BeatLoaderContainer,
    CloseIcon, CloseIconContainer,
    Container,
    Icon,
    Image,
    ImageContainer,
    Label,
    Loader,
    LoaderText
} from "./LoadImageToolStyles";
import {faUpload, faTimes, faInfoCircle, faMinus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BeatLoader} from "react-spinners";
import { showRightMenu } from "../RightMenu/store/actions";

export default function LoadImageTool() {
    const dispatch = useDispatch();
    const {present} = useSelector(state => state.board);
    const {columns, rows} = present;
    const palette = useSelector(state => state.colorsTool.palette.name)
    const {clearHash} = useHash();
    const [imagePreview, setImagePreview] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const onDrop = useCallback(acceptedFiles => {
        setIsLoading(true);
        if (acceptedFiles.length <= 0) {
            return;
        }

        const file = acceptedFiles[0];
        setImagePreview(URL.createObjectURL(file));

        const formData = new FormData();
        formData.append('image', file);
        formData.append('columns', columns.toString());
        formData.append('rows', rows.toString());
        formData.append('palette', palette.toString());

        fetch(process.env.REACT_APP_API_URL + '/image-processor/process', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(image => {
                if (Array.isArray(image)) {
                    dispatch(loadImage(image));
                }
                clearHash();
                setIsLoading(false);
            });
    }, [clearHash, columns, dispatch, palette, rows])

    const {getRootProps, getInputProps} = useDropzone({onDrop})
    const show = () => dispatch(showRightMenu());

    return <>
        <Label>
            <div>zamień zdjęcie na planszę</div>
            <div>
                <FontAwesomeIcon icon={faInfoCircle} onClick={() => show()}/>
                <FontAwesomeIcon icon={faMinus}/>
            </div>
        </Label>
        <Container>
            {isLoading && <BeatLoaderContainer>
                <BeatLoader
                    size={25}
                    color={"#3ba900"}
                    loading={true}
                />
            </BeatLoaderContainer>}
            <div>
                <Loader {...getRootProps()}>
                    <input {...getInputProps()}/>
                    <LoaderText>
                        <span>wgraj zdjęcie</span>
                        <Icon>
                            <FontAwesomeIcon icon={faUpload}/>
                        </Icon>
                    </LoaderText>
                </Loader>
            </div>
            {imagePreview && <ImageContainer>
                <Image src={imagePreview}>
                    <CloseIconContainer title="Usuń" onClick={() => setImagePreview(null)}>
                        <CloseIcon>
                            <FontAwesomeIcon icon={faTimes}/>
                        </CloseIcon>
                    </CloseIconContainer>
                </Image>
            </ImageContainer>}
        </Container>
    </>
}
